import React from "react";
import { graphql } from "gatsby";
import Page from "./page";
import ShuffleFilter from "../components/Shuffle/shuffleFilter";
import Seo from "../components/seo";
import useFlags from "../components/useFlags";

const CaseStudies = ({ path, location, data: { mdx, allMdx } }) => {
  const { show_case_studies_shuffle } = useFlags();
  return (
    <>
      <Seo
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description}
        pathname={path}
      />
      <div className="mb-5">
        <Page location={location} mdx={mdx} />
        {show_case_studies_shuffle && <ShuffleFilter allMdx={allMdx} />}
      </div>
    </>
  );
};
export default CaseStudies;

export const query = graphql`
  query CaseStudies($locale: String!, $slug: String!) {
    mdx(fields: { locale: { eq: $locale }, slug: { eq: $slug } }) {
      frontmatter {
        title
        description
      }
      fields {
        slug
      }
      body
    }
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/(case_studies)/.*.mdx$/" }
        fields: { locale: { eq: $locale } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            categories
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  quality: 100
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
