import React, { useEffect, useRef, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Shuffle from "shufflejs";
import LocalizedLink from "../LocalizedLink";
import useTranslations from "../useTranslations";

const ShuffleFilter = ({ allMdx }) => {
  const sizer = useRef(null);
  const container = useRef(null);
  const shuffle = useRef(null);
  const [value, setValue] = useState("all");
  const { translate } = useStaticQuery(query);
  const { all } = useTranslations(translate);

  let categories = ["all"];
  allMdx.edges.forEach(({ node }) => {
    node.frontmatter.categories.forEach((category) => {
      if (!categories.includes(category)) categories.push(category);
    });
  });

  const handleFilter = (value) => {
    shuffle.current.filter(value);
    setValue(value);
  };

  useEffect(() => {
    if (container && sizer) {
      shuffle.current = new Shuffle(container.current, {
        buffer: 0,
        columnThreshold: 0.01,
        group: Shuffle.ALL_ITEMS,
        itemSelector: ".shuffle-item",
        speed: 300,
        sizer: sizer.current,
        isCentered: true,
      });
      shuffle.current.filter("all");
    }

    return () => {
      shuffle.current.destroy();
    };
  }, [container, sizer, shuffle]);

  return (
    <>
      <div className="container">
        <div className="shuffle-filter filter-options">
          {categories.map((item, index) => {
            return (
              <button
                key={index}
                className={`btn btn-style-3 ${item === value ? "active" : ""}`}
                data-group={item}
                onClick={() => handleFilter(item)}
              >
                {item === "all" ? all.toUpperCase() : item.toUpperCase()}
              </button>
            );
          })}
        </div>
      </div>
      <div className="container">
        <div ref={container} className="shuffle container-fluid ">
          {allMdx.edges.map(({ node }, index) => {
            let dataGroups = `["all"`;
            node.frontmatter.categories.forEach((category, index, array) => {
              dataGroups = dataGroups.concat(
                `, "${category}"${index + 1 === array.length ? "]" : ""}`
              );
            });
            return (
              <figure
                data-groups={`${dataGroups}`}
                key={index}
                className="shuffle-item  col-lg-3 col-md-4 col-sm-8 "
              >
                <LocalizedLink
                  to={node.fields.slug}
                  type="case study"
                  aria-label={node.frontmatter.title}
                >
                  <div className="shuffle-item--inner">
                    <div className="suffle-item--aspect">
                      <GatsbyImage
                        image={getImage(node.frontmatter.image)}
                        alt={node.frontmatter.title}
                        className="shuffle-item--picture img-fluid"
                      />
                    </div>
                    <div className="shuffle-item--label">
                      <span className="shuffle-item--title">
                        {node.frontmatter.title}
                      </span>
                      <span className="shuffle-item--description">
                        {node.frontmatter.description}
                      </span>
                    </div>
                  </div>
                </LocalizedLink>
              </figure>
            );
          })}
          <div ref={sizer} className="col-2"></div>
        </div>
      </div>
    </>
  );
};

export default ShuffleFilter;

const query = graphql`
  query ShuffleFilter {
    translate: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            all
          }
        }
      }
    }
  }
`;
